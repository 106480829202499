import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import logo from './Assets/images/logo/Blue Minimalist Investment Company Logo.png';
import { FiMoon, FiSun } from "react-icons/fi";
import { toggleDarkMode, uiStore, closeDropdown } from "../src/features/uiSlice";
import { Routes, Route, useLocation } from "react-router-dom";
import BackToTopButton from "./components/common/BackToTopButton";
import Footer from "./components/common/Footer";
import Navbar from "./components/common/Navbar";
import {
  About,
  Services,
  Property,
  Blog,
  Contact,
  PageNotFound,
  Home,
  PropertyDetailList,
  Category
} from "./pages";
import Dropdown from "./components/common/DropDown";
// import Loader from "./components/common/Loader";


function App() {
  const [showButton, setShowButton] = useState(false);
  // const [showLoader, setShowLoader] = useState(true);  
  const { darkMode } = useSelector(uiStore);
  const dispatch = useDispatch();
  const route = useLocation();
  const rootDoc = document.querySelector(":root");

  useEffect(() => {
    const savedDarkMode = JSON.parse(localStorage.getItem("Seaboard")) || false;
    if (savedDarkMode) {
      dispatch(toggleDarkMode());
    }
  }, [dispatch]);


  const handleDarkMode = () => {
    dispatch(toggleDarkMode());
  };


  useEffect(() => {
    if (darkMode) {
      rootDoc.classList.remove("dark");
    } else {
      rootDoc.classList.add("dark");
    }
    localStorage.setItem("Seaboard", JSON.stringify(darkMode));
  }, [darkMode, rootDoc.classList]);

  // Show/Hide scroll to top button
  useEffect(() => {
    const handleScroll = () => {
      setShowButton(window.scrollY > 500);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Page title based on route
  useEffect(() => {
    const titles = {
      "/": "Seaboard Property - Home",
      "/about-us": "Seaboard Property - About Us",
      "/services": "Seaboard Property - Services",
      "/property": "Seaboard Property - Property",
      "/blog": "Seaboard Property - Blog",
      "/contact": "Seaboard Property - Contact",
      "/category": "Seaboard Property - Category",
      "/property/:id": "Seaboard Property - Details",
    };
    document.title = titles[route.pathname] || "Seaboard - Page Not Found";
  }, [route]);

  // Hide loader after page load
  // useEffect(() => {
  //   const handleLoad = () => {
  //     setShowLoader(false);
  //   };
  //   window.addEventListener("load", handleLoad);
  //   return () => {
  //     window.removeEventListener("load", handleLoad);
  //   };
  // }, []);

  const handleCloseDropdown = () => {
    dispatch(closeDropdown());
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [route]);

  return (
    <>
      <div>
        {/* {showLoader && <Loader />} */}
        <Navbar />
        <Dropdown />
        <div
          className="min-h-screen pb-10"
          onClick={handleCloseDropdown}
          onMouseOver={() => dispatch(closeDropdown())}
        >
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about-us" element={<About />} />
            <Route path="/services" element={<Services />} />
            <Route path="/property" element={<Property />} />
            <Route path="/property/:id" element={<PropertyDetailList />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/home" element={<Home />} />
            <Route path="/category" element={<Category />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </div>
        <div className="px-[2%] md:px-[6%] bg-card-dark border border-card-dark rounded-tl-[100px]  rounded-tr-[100px] ">
          <div className="mt-10">
            <Footer />
          </div>
        </div>
        <BackToTopButton showButton={showButton} />
      </div>
      <div
        className="fixed bg-primary z-10 shadow-md top-28 right-8 icon-box text-white dark:bg-dark-light hover:shadow-lg hover:bg-black"
        onClick={handleDarkMode}
      >
        {darkMode ? <FiMoon /> : <FiSun />}
      </div>


      <FloatingWhatsApp phoneNumber="+92 307 6144914" accountName="SEABOARD PROPERTY" avatar={logo} />
      
      
    </>
  );
}

export default App;
