// import { useSelector } from "react-redux";
// import { dataStore } from "../../../features/dataSlice";
import SingleProductCard from "../../common/page-componets/SingleProductCard";
import { useSearchParams } from "react-router-dom";
import nodata from '../../../Assets/images/nodatafoundimg.avif';
import { property } from "../../../data/dummyData";
import 'wow.js/css/libs/animate.css'; 

const PropertyList = ({ basis }) => {
  const [searchParams] = useSearchParams();

  // Default search parameters
  const params = {
    location: '',
    propertyType: '',
    priceRange: '',
    purpose: ''
  };

  // Update params based on searchParams
  searchParams.forEach((value, key) => {
    params[key] = value;
  });

  // // Fetch data from Redux store
  // const { currentDataItems } = useSelector(dataStore);

  // // Debugging: log search params and current data items
  // // console.log('Search Params:', params);
  // console.log('Current Data Items:', currentDataItems);

  // Filter properties based on search parameters
  const filteredProperties = property?.filter(val => {
    // Handle priceRange if it's not defined
    const [minPrice, maxPrice] = params.priceRange ? params.priceRange.split('-').map(Number) : [0, Infinity];

   

    // Check if propertyType is empty or if it matches
    const matchesPropertyType = params.propertyType ? val.propertyType.toLowerCase() === params.propertyType.toLowerCase() : true;
    const matchesLocation = params.location ? val.location.toLowerCase().includes(params.location.toLowerCase()) : true;
    const matchesPurpose = params.purpose ? val.purpose.toLowerCase().includes(params.purpose.toLowerCase()) : true;
    const matchesPrice = (!params.priceRange || (val.price >= minPrice && val.price <= maxPrice));

    return matchesPropertyType && matchesLocation && matchesPurpose && matchesPrice;
  });


  return (
    <div className="flex flex-wrap gap-4" >
      {filteredProperties?.length > 0 ? (
        filteredProperties.map((property) => (
          <SingleProductCard key={property.id} {...property} basis={basis} />
        ))
      ) : (
        <div className="container w-full rounded-2xl flex justify-center">
          <img className="object-contain h-96 w-full" src={nodata} alt="No data found" />
        </div>
      )}
    </div>
  );
};

export default PropertyList;
