import { useEffect, useRef, useState } from "react";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import { categories } from "../../../data/dummyData";

const Categories = () => {
  const categoryContainer = useRef(null);
  const [isScroll, setIsScroll] = useState(false);
  const [propertyType, setPropertyType] = useState('');

  const navigate = useNavigate();

  const handleSearch = () => {
    navigate(`/property?propertyType=${propertyType}`);
  };

  const handleCategoryClick = (cat) => {
    setPropertyType(cat);
  };

  useEffect(() => {
    if (propertyType) {
      handleSearch();
    }
  }, [propertyType]);

  const scrollContainer = (direction) => {
    const container = categoryContainer.current;
    const scrollAmount = 200;
    const newScrollLeft = direction === "right"
      ? container.scrollLeft + scrollAmount
      : container.scrollLeft - scrollAmount;

    container.scrollTo({
      left: newScrollLeft,
      behavior: "smooth"
    });

    const maxScrollLeft = container.scrollWidth - container.clientWidth;
    setIsScroll(container.scrollLeft > 0 && container.scrollLeft < maxScrollLeft);
  };

  useEffect(() => {
    const container = categoryContainer.current;

    const autoScroll = () => {
      const maxScrollLeft = container.scrollWidth - container.clientWidth;

      if (container.scrollLeft >= maxScrollLeft) {
        container.scrollLeft = 0; // Reset to start
      } else if (container.scrollLeft < 0) {
        container.scrollLeft = maxScrollLeft; // Reset to end
      } else {
        container.scrollLeft += 1; // Adjust the scroll speed here
      }
    };

    const scroll = () => {
      autoScroll();
      requestAnimationFrame(scroll);
    };

    const animationFrame = requestAnimationFrame(scroll);

    return () => cancelAnimationFrame(animationFrame);
  }, []);

  return (
    <div className="pt-10 pb-16">
      <div>
        <h1 className="heading text-center">Find Your Dream House</h1>
        {/* <div className="flex justify-between items-center gap-x-3">
          <button
            className={`btn btn-primary !p-2 ${!isScroll && "opacity-50 cursor-not-allowed"}`}
            onClick={() => scrollContainer("left")}
          >
            <FiChevronLeft />
          </button>
          <button
            className="btn btn-primary !p-2"
            onClick={() => scrollContainer("right")}
          >
            <FiChevronRight />
          </button>
        </div> */}

        <div
          className="gap-3 mt-4 overflow-auto flex align-center scroll-smooth hide-scrollbar"
          ref={categoryContainer}
        >
          {categories.map(({ id, name, image, cat }) => (
            <div
              key={id}
              className="relative flex-shrink-0 w-[300px] group rounded-lg overflow-hidden"
            >
              <div onClick={() => handleCategoryClick(cat)} className="overflow-hidden rounded-lg cursor-pointer">
                <Link className="!opacity-100">
                  <img
                    src={image}
                    alt={name}
                    className="w-full h-[300px] object-cover group-hover:scale-125 transition-transform"
                  />
                </Link>
              </div>
              <div className="absolute bottom-0 left-0 w-full px-2 py-2 transition-transform bg-gradient-to-t from-black/80 text-slate-100 to-transparent">
                <h1 className="text-lg font-semibold">{name}</h1>
              </div>
            </div>
          ))}
        </div>
        <div className="flex justify-end">
          <button className="mt-4 btn btn-primary">
            <Link to="/category">All Categories</Link>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Categories;
