import React, { useState } from 'react';
import { BiBed, BiMap, BiTab } from "react-icons/bi";
import { HiHomeModern } from "react-icons/hi2";
import { TbDimensions } from "react-icons/tb";
import { MdOutlinePhone } from "react-icons/md";
import { useParams } from 'react-router-dom';
// import ImageSlider from "image-slider-react";
import { property } from '../../../data/dummyData';
// import GetInTouch from '../../common/GetInTouch';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MdEmail } from 'react-icons/md';
import { FaWhatsapp } from 'react-icons/fa';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { FaRegArrowAltCircleRight, FaRegArrowAltCircleLeft } from "react-icons/fa";



const PropertyDetailPage = () => {
  const { id } = useParams();
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [filters, setFilters] = useState({});
  const [filteredProperties, setFilteredProperties] = useState(property);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });
  const phoneNumber = "+1234567890";
  const email = 'example@example.com';

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const selectedProperty = filteredProperties.find(p => p.id === parseInt(id));

  const handleSearch = (searchFilters) => {
    setFilters(searchFilters);
    // Filter properties based on the search criteria
    const filtered = property.filter(p => {
      const matchesLocation = searchFilters.location ? p.location.toLowerCase().includes(searchFilters.location.toLowerCase()) : true;
      const matchesType = searchFilters.propertyType ? p.propertyType.toLocaleLowerCase() === searchFilters.propertyType : true;
      const matchesPrice = searchFilters.priceRange ? isPriceInRange(p.price, searchFilters.priceRange) : true;
      const matchesPurpose = searchFilters.purpose ? p.purpose === searchFilters.purpose : true;

      return matchesLocation && matchesType && matchesPrice && matchesPurpose;
    });
    setFilteredProperties(filtered);
  };

  const isPriceInRange = (price, range) => {
    const [min, max] = range.split(' - ').map(Number);
    return price >= min && price <= max;
  };

  if (!selectedProperty) {
    return <div>Property not found</div>;
  }




  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Basic validation
    const { name, email, phone, message } = formData;
    if (!name || !email || !phone || !message) {
      alert("Please fill out all required fields.");
      return;
    }

    if (message.length < 20) {
      alert("Message must be at least 20 characters long.");
      return;
    }

    // Simulate form submission
    toast.success('Your message has been sent successfully!', {
      position: toast.POSITION.TOP_RIGHT,
    });

    // Clear form fields
    setFormData({
      name: '',
      email: '',
      phone: '',
      message: '',
    });
  };


  const fullImage = () => {
    setIsImageModalOpen(true);
  };

  const closeImageModal = () => {
    setIsImageModalOpen(false);
  };



  const add = () => {
    setCurrentSlide(prevSlide => (prevSlide + 1) % selectedProperty.image.length);
  };

  const subtract = () => {
    setCurrentSlide(prevSlide => (prevSlide - 1 + selectedProperty.image.length) % selectedProperty.image.length);
  };




  return (
    <>

      <div className="pt-48 px-4 md:px-8 lg:px-16">
        <h1 className="text-xl md:text-2xl lg:text-3xl font-semibold">
          HOME / <span className="text-primary">PROPERTY DETAILS</span>
        </h1>

        <div className="flex flex-col md:flex-row border rounded-xl mt-10">
          <div className="w-full md:w-1/2 p-4 ">
          
          <CarouselProvider
  naturalSlideWidth={100}
  naturalSlideHeight={125}
  totalSlides={selectedProperty.image.length}
  className="relative w-full md:h-[550px] h-[350px] rounded"
>
  <Slider onAfterSlide={index => setCurrentSlide(index)}>
    {selectedProperty.image.map((imgSrc, index) => (
      <Slide key={index}>
        <img onClick={fullImage}
          src={imgSrc}
          alt={`${selectedProperty.name} image ${index + 0}`}
          className="object-cover rounded-lg  cursor-pointer w-full md:h-[550px] h-[350px]   transition-transform duration-300"
        />
      </Slide>
    ))}
  </Slider>
  <ButtonBack onClick={subtract} className="absolute top-1/2 left-0 transform -translate-y-1/2 h-12 w-12 flex items-center justify-center text-white text-3xl rounded-full">
    <FaRegArrowAltCircleLeft />
  </ButtonBack>
  <ButtonNext onClick={add}  className="absolute top-1/2 right-0 transform -translate-y-1/2 h-12 w-12 flex items-center justify-center text-white text-3xl rounded-full">
    <FaRegArrowAltCircleRight />
  </ButtonNext>
  <div className="absolute bottom-0 left-0 w-full flex items-center justify-end">
    <p className="text-white bg-black/50 p-1 rounded-md">{currentSlide + 1} / {selectedProperty.image.length}</p>
  </div>
</CarouselProvider>


          </div>
          <div className="w-full md:w-1/2 p-4 ">
            <h1 className="text-xl md:text-2xl  lg:text-3xl font-extrabold text-primary">{selectedProperty.name}</h1>
            <div className="flex flex-wrap gap-4 mt-4">
              <div className="flex items-center gap-2">
                <div className="icon-box !w-7 !h-7 bg-primary/20 hover:!bg-primary/40 text-primary">
                  <BiBed />
                </div>
                <p className="text-sm dark:text-white">{selectedProperty.number_of_beds} Beds</p>
              </div>
              <div className="flex items-center gap-2">
                <div className="icon-box !w-7 !h-7 bg-primary/20 hover:!bg-primary/40 text-primary">
                  <BiTab />
                </div>
                <p className="text-sm dark:text-white">{selectedProperty.number_of_bathrooms} Bathrooms</p>
              </div>
            </div>
            <div className="flex flex-wrap gap-4 mt-4">
              <div className="flex items-center gap-2">
                <div className="icon-box !w-7 !h-7 bg-primary/20 hover:!bg-primary/40 text-primary">
                  <BiBed />
                </div>
                <p className="text-sm dark:text-white">{selectedProperty.number_of_beds} Beds</p>
              </div>
              <div className="flex items-center gap-2">
                <div className="icon-box !w-7 !h-7 bg-primary/20 hover:!bg-primary/40 text-primary">
                  <BiTab />
                </div>
                <p className="text-sm dark:text-white">{selectedProperty.number_of_bathrooms} Bathrooms</p>
              </div>
            </div>
            <div style={{ fontWeight: "800" }} className="text-4xl md:text-6xl dark:text-white lg:text-7xl  mt-4">
  Price : 
  <span className="text-primary">
    {selectedProperty.price}  
    {selectedProperty.dd_MM_yy && (
      <span className='text-4xl md:text-6xl  lg:text-7xl font-extrabold text-slate-700 dark:text-white'>
        {` / ${selectedProperty.dd_MM_yy}`}
      </span>
    )}
  </span>
</div>

            <div className="text-gray-900 dark:text-slate-400 text-xl mt-4 flex items-center gap-2">
              <BiMap />
              <h1 className='dark:text-white'>{selectedProperty.location}</h1>
            </div>
            <div className=''>

              <button type="submit" className=" mt-4 btn btn-primary flex justify-between items-center "> <a
                href={`tel:${phoneNumber}`}
              >
                Call
              </a></button>
            </div>
          </div>
        </div>

        <div className="bg-gray-100 dark:bg-gray-800 text-gray-900 dark:text-slate-400 p-4 mt-6 rounded-lg">
  <div className="flex flex-wrap gap-4 mt-4">
    <div className="icon-box !w-7 !h-7 bg-primary/20 hover:!bg-primary/40 text-primary">
      <HiHomeModern />
    </div>
    <p className="text-xl dark:text-white">Purpose: {selectedProperty.purpose}</p>
  </div>
  <div className="flex flex-wrap gap-4 mt-4">
    <div className="icon-box !w-7 !h-7 bg-primary/20 hover:!bg-primary/40 text-primary">
      <TbDimensions />
    </div>
    <p className="text-xl dark:text-white">Dimensions: {selectedProperty.dimensions}</p>
  </div>
  <div className="flex flex-wrap gap-4 mt-4">
    <div className="icon-box !w-7 !h-7 bg-primary/20 hover:!bg-primary/40 text-primary">
      <MdOutlinePhone />
    </div>
    <p className="text-xl dark:text-white">Contact: {selectedProperty.contact}</p>
  </div>

  <p className="mt-4 text-lg dark:text-white"><h1 className='text-2xl md:text-3xl lg:4xl'>Description : </h1>{selectedProperty.description}</p>
  <div className="mt-6 flex flex-col sm:flex-row justify-between">
    <div className="mb-4 sm:mb-0">
      <h2 className="text-xl font-semibold dark:text-white">Dealer Info</h2>
      <p className="text-gray-600 dark:text-white">Dealer: {selectedProperty.dealer}</p>
    </div>
    <div className="flex flex-col sm:flex-row gap-3 sm:gap-5">
      <a
        href={`https://wa.me/${phoneNumber}`}
        target="_blank"
        rel="noopener noreferrer"
        className="mt-4 btn btn-primary flex items-center justify-center sm:justify-between"
      >
        <FaWhatsapp className="mr-2" /> Whatsapp
      </a>
      <a
        href={`mailto:${email}`}
        className="mt-4 btn btn-primary flex items-center justify-center sm:justify-between"
      >
        <MdEmail className="mr-2" /> Email
      </a>
      <button onClick={openModal} className="mt-4 btn btn-primary flex items-center justify-center sm:justify-between">
        <MdOutlinePhone className="mr-2" /> Contact
      </button>
    </div>
  </div>
  <iframe src={selectedProperty.map} className='w-full mt-5 border' height="150" />
</div>

        <div className='w-full'>
        </div>
      </div>
      {isModalOpen && (
        <div
          id="default-modal"
          tabIndex="-1"
          aria-hidden="true"
          className="fixed inset-0 z-50  flex items-center justify-center   bg-gray-800 bg-opacity-50"
        >

          <div className='md:w-4/12 w-9/12 rounded-lg  bg-slate-100 border dark:bg-dark'>
            <div className="flex items-center justify-end p-4 border-b rounded-t dark:border-gray-600">

              <button
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                onClick={closeModal}
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
              </button>
            </div>

            <div className="max-w-[450px]  w-full  mx-auto    p-3 rounded-lg">

              <div className="mt-4">
                <form onSubmit={handleSubmit}>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    className="w-full px-2 py-1 border rounded-md outline-none bg-transparent"
                    placeholder="Your name.."
                    required
                  />
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="w-full px-2 py-1 mt-3 border rounded-md outline-none bg-transparent"
                    placeholder="Your email.."

                  />
                  <input
                    type="tel"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    // pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                    className="w-full px-2 py-1 mt-3 border rounded-md outline-none bg-transparent"
                    placeholder="Your Phone No.."
                    required
                  />
                  <textarea
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    className="w-full p-2 mt-3 border rounded-md outline-none bg-transparent"
                    rows={3}
                    placeholder="Your message.."
                    required
                    minLength={20}
                  ></textarea>
                  <button type="submit" className="w-full mt-4 btn btn-primary">Submit</button>
                </form>
                <ToastContainer />
              </div>
            </div>
          </div>
        </div>
      )}
{isImageModalOpen && (
  <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-90">
    <div className="relative w-full md:w-9/12 lg:w-8/12 xl:w-5/12 md:h-1/3 h-60 bg-slate-800 rounded-lg shadow-lg overflow-hidden">
      <CarouselProvider
        naturalSlideWidth={100}
        naturalSlideHeight={125}
        totalSlides={selectedProperty.image.length}
        className="relative w-full h-full"
      >
        <Slider onAfterSlide={index => setCurrentSlide(index)} className="w-full h-full">
          {selectedProperty.image.map((imgSrc, index) => (
            <Slide key={index}>
              <img 
                onClick={fullImage}
                src={imgSrc}
                alt={`${selectedProperty.name} image ${index + 1}`}
                className="object-cover w-full h-80 transition-transform duration-300 cursor-pointer"
              />
            </Slide>
          ))}
        </Slider>
        <ButtonBack onClick={subtract} className="absolute top-1/2 left-2 transform -translate-y-1/2 h-10 w-10 flex items-center justify-center text-white text-xl rounded-full bg-black opacity-75 hover:opacity-100 transition-opacity duration-300">
          <FaRegArrowAltCircleLeft />
        </ButtonBack>
        <ButtonNext onClick={add} className="absolute top-1/2 right-2 transform -translate-y-1/2 h-10 w-10 flex items-center justify-center text-white text-xl rounded-full bg-black opacity-75 hover:opacity-100 transition-opacity duration-300">
          <FaRegArrowAltCircleRight />
        </ButtonNext>
        <div className="absolute bottom-2 left-1/2 transform -translate-x-1/2 w-full flex items-center justify-center">
          <p className="text-white bg-black bg-opacity-60 p-2 rounded-md">{currentSlide + 1} / {selectedProperty.image.length}</p>
        </div>
      </CarouselProvider>
      <button
        className="absolute top-2 right-2 text-white text-2xl bg-black p-2 rounded-full opacity-75 hover:opacity-100 transition-opacity duration-300"
        onClick={closeImageModal}
      >
        &times;
      </button>
    </div>
  </div>
)}


    </>
  );
};

export default PropertyDetailPage;
