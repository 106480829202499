// import { useSelector } from "react-redux";
// import { dataStore } from "../../../features/dataSlice";

import { useSearchParams } from "react-router-dom";
import nodata from '../../../Assets/images/nodatafoundimg.avif'
import SingleProductCardFullWidth from "../../common/page-componets/SingleProductCardFullWidth";
import { property } from "../../../data/dummyData";
const PropertyListFull = ({ basis }) => {
  const [searchParams] = useSearchParams();

  // Default search parameters
  const params = {
    location: '',
    propertyType: '',
    priceRange: '',
    purpose: ''
  };

  // Update params based on searchParams
  searchParams.forEach((value, key) => {
    params[key] = value;
  });

  // Fetch data from Redux store
  // const { currentDataItems } = useSelector(dataStore);


  // Filter properties based on search parameters
  const filteredProperties = property?.filter(val =>
    val.location?.toLowerCase().includes(params.location.toLowerCase()) &&
    (val.propertyType ? val.propertyType.toLowerCase().includes(params.propertyType.toLowerCase()) : true) &&
    val.purpose?.toLowerCase().includes(params.purpose.toLowerCase())
  );



  return (
    <>
      <div className="flex flex-wrap gap-4">
        {filteredProperties?.length > 0 ? (
          filteredProperties.map((property) => (
            <SingleProductCardFullWidth key={property.id} {...property} basis={basis} />

          ))
        ) : (
          <div className="container w-full rounded-2xl flex justify-center">
            <img className="object-contain  h-96 w-full" src={nodata} alt="" />
          </div>


        )}
      </div>
    </>





  );
};

export default PropertyListFull;
