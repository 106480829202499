import React, { useState, useEffect } from "react";

const Map = () => {
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    const handleLoad = () => setShowLoader(false);
    window.addEventListener("load", handleLoad);
    return () => window.removeEventListener("load", handleLoad);
  }, []);

  return (
    <>
      {showLoader && (
        <div className="flex justify-center ">
          <div className="custom-loader">
            <div className="loader"></div>
          </div>
        </div>
      )}
      <div className="rounded-lg">
        <iframe
          className="rounded-lg"
          title="Google Map"
          src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d115876.33841478104!2d67.0203904!3d24.8250368!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2s!4v1715849254858!5m2!1sen!2s"
          width="100%"
          height="450"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          onLoad={() => setShowLoader(false)}
        ></iframe>
      </div>
    </>
  );
};

export default Map;
