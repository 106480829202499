import React, { useEffect } from 'react';
import {
  Featured,
  Services,
  Categories
} from "../components/common/page-componets";
import Testimonial from "../components/common/Testimonial";
import {
  Feeds,
  Filters,
  Hero
} from "../components/home/home";
import 'wow.js/css/libs/animate.css'; 
import WOW from 'wow.js';


const Home = () => {
  useEffect(() => {

    const wow = new WOW({
      live: false,
      offset: 100,
      mobile: true
    });
    wow.init();

    
    return () => {
    };
  }, []);

  return (
    <>
      <Hero />
      <div className="pt-16 px-[3%] md:px-[6%]">
          <div id="filters" className="wow fadeInLeft" data-wow-duration="2s" data-wow-delay="1mms">
            <Filters />
          </div>
        
          <div id="services" className="wow fadeInRight" data-wow-duration="2s" data-wow-delay="2mms">
            <Services />
          </div>
        
          <div id="featured" className="wow fadeInLeft" data-wow-duration="2s" data-wow-delay="1mms">
            <Featured />
          </div>
        
          <div id="feeds" className="wow fadeInRight" data-wow-duration="2s" data-wow-delay="1mms">
            <Feeds />
          </div>
        
         
            <Categories />
         
        
          <div id="testimonial" className="wow fadeInUp" data-wow-duration="3s" data-wow-delay="1mms">
            <Testimonial />
          </div>

      </div>
    </>
  );
};

export default Home;
