import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Filters = ({ onSearch }) => {
  const navigate = useNavigate();
  const [location, setLocation] = useState('');
  const [propertyType, setPropertyType] = useState('');
  const [priceRange, setPriceRange] = useState('');
  const [purpose, setPurpose] = useState('');

  const handleSearch = () => {
    navigate(`/property?location=${encodeURIComponent(location)}&propertyType=${encodeURIComponent(propertyType)}&priceRange=${encodeURIComponent(priceRange)}&purpose=${encodeURIComponent(purpose)}`);
    // onSearch({ location, propertyType, priceRange, purpose });
  };

  return (
    <div className="md:max-w-[80%]  w-full mx-auto relative  ">
      <div className="flex-col md:bg-primary/30 bg-white/10 backdrop-blur-2xl md:-mt-28 -mt-48  gap-x-4 flex-center-between gap-y-4 md:gap-y-0 md:flex-row card card-shadow dark:shadow-none">

        {/* Location Filter */}
        <div className="flex-1 w-full p-2 rounded-lg md:w-fit bg-transparent dark:bg-hover-color-dark">
          <label htmlFor="location" className="font-bold">Location</label>
          <input
            type="text"
            id="location"
            className="w-full bg-transparent border-0 outline-none dark:bg-transparent dark:text-white dark:custom-b-placholer custom-placeholder"
            placeholder="Enter location of the property"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
          />
        </div>
        <span className="hidden md:block">|</span>

        {/* Property Type Filter */}
        <div className="flex-1 w-full p-2 rounded-lg md:w-fit bg-transparent dark:bg-hover-color-dark">
          <label htmlFor="property-type" className="font-bold">Property Type</label>
          <select
            id="property-type"
            className="w-full bg-transparent border-0 outline-none dark:bg-transparent dark:text-white opacity-70"
            value={propertyType}
            onChange={(e) => setPropertyType(e.target.value)}
          >
            <option className='dark:bg-dark-light dark:text-white opacity-70' value="">Select Property Type</option>
            <option className='dark:bg-dark-light dark:text-white opacity-70' value="office">Office</option>
            <option className='dark:bg-dark-light dark:text-white opacity-70' value="shop">Shop</option>
            <option className='dark:bg-dark-light dark:text-white opacity-70' value="penthouse">Penthouse</option>
            <option className='dark:bg-dark-light dark:text-white opacity-70' value="condos">Condos</option>
            <option className='dark:bg-dark-light dark:text-white opacity-70' value="appartments">Apartments</option>
            <option className='dark:bg-dark-light dark:text-white opacity-70' value="mansions">Mansions</option>
            <option className='dark:bg-dark-light dark:text-white opacity-70' value="villas">Villas</option>
          </select>
        </div>
        <span className="hidden md:block">|</span>

        {/* Price Range Filter */}
        <div className="flex-1 w-full p-2 rounded-lg md:w-fit bg-transparent dark:bg-hover-color-dark">
          <label htmlFor="price-range" className="font-bold">Price Range</label>
          <select
            id="price-range"
            className="w-full bg-transparent border-0 outline-none dark:bg-transparent dark:text-white opacity-70"
            value={priceRange}
            onChange={(e) => setPriceRange(e.target.value)}
          >
            <option className='dark:bg-dark-light dark:text-white opacity-70' value="">Select Price Range</option>
            <option className='dark:bg-dark-light dark:text-white opacity-70' value="100-1000">100 - 1,000</option>
            <option className='dark:bg-dark-light dark:text-white opacity-70' value="1000-2000">1,000 - 2,000</option>
            <option className='dark:bg-dark-light dark:text-white opacity-70' value="2000-5000">2,000 - 5,000</option>
            <option className='dark:bg-dark-light dark:text-white opacity-70' value="5000-10000">5,000 - 10,000</option>
          </select>
        </div>
        <span className="hidden md:block">|</span>

        {/* For Filter */}
        <div className="flex-1 w-full p-2 rounded-lg md:w-fit bg-transparent dark:bg-hover-color-dark">
          <label htmlFor="purpose" className="font-bold">For</label>
          <select
            id="purpose"
            className="w-full bg-transparent border-0 outline-none dark:bg-transparent dark:text-white opacity-70"
            value={purpose}
            onChange={(e) => setPurpose(e.target.value)}
          >
            <option className='dark:bg-dark-light dark:text-white opacity-70' value="">Select Purpose</option>
            <option className='dark:bg-dark-light dark:text-white opacity-70' value="sale">Sale</option>
            <option className='dark:bg-dark-light dark:text-white opacity-70' value="rent">Rent</option>
          </select>
        </div>

        {/* Search Button */}
        <button
          className="w-full btn btn-primary md:w-fit"
          onClick={handleSearch}
        >
          Search
        </button>
      </div>
    </div>
  );
};

export default Filters;
