export const navLinks = [
  {
    id: 1,
    linkText: "Home",
    url: "/"
  },
  {
    id: 2,
    linkText: "About Us",
    url: "/about-us",

  },
  {
    id: 3,
    linkText: "Find A Property",
    url: "/property",
    subLinks: [
      {
        id: 1,
        linkText: "Property For Sale",
        url: "/property?purpose=sale",
      },
      {
        id: 2,
        linkText: "Property For Rent",
        url: "/property?purpose=rent",
      }
    ]
  },
  {
    id: 4,
    linkText: "Category",
    url: "/category",
    subLinks: [
      {
        id: 1,
        linkText: "Offices",
        url: "/property?propertyType=office",
        miniSubLinks: [
          {
            id: 1,
            linkText: "Rent",
            url: "/property?propertyType=office&purpose=rent",
          },
          {
            id: 2,
            linkText: "Sale",
            url: "/property?propertyType=office&purpose=sale",
          }
        ]
      },
      {
        id: 2,
        linkText: "Shops",
        url: "/property?propertyType=shop",
        miniSubLinks: [
          {
            id: 1,
            linkText: "Rent",
            url: "/property?propertyType=shop&purpose=rent",
          },
          {
            id: 2,
            linkText: "Sale",
            url: "/property?propertyType=shop&purpose=sale",
          }
        ]
      },
      {
        id: 3,
        linkText: "Villas",
        url: "/property?propertyType=villas",
        miniSubLinks: [
          {
            id: 1,
            linkText: "Rent",
            url: "/property?propertyType=villas&purpose=rent",
          },
          {
            id: 2,
            linkText: "Sale",
            url: "/property?propertyType=villas&purpose=sale",
          }
        ]
      },
      {
        id: 4,
        linkText: "Condos",
        url: "/property?propertyType=condos",
        miniSubLinks: [
          {
            id: 1,
            linkText: "Rent",
            url: "/property?propertyType=condos&purpose=rent",
          },
          {
            id: 2,
            linkText: "Sale",
            url: "/property?propertyType=condos&purpose=sale",
          }
        ]
      },
      {
        id: 5,
        linkText: "Mansion",
        url: "/property?propertyType=mansions",
        miniSubLinks: [
          {
            id: 1,
            linkText: "Rent",
            url: "/property?propertyType=mansions&purpose=rent",
          },
          {
            id: 2,
            linkText: "Sale",
            url: "/property?propertyType=mansions&purpose=sale",
          }
        ]
      },
      {
        id: 6,
        linkText: "Appartments",
        url: "/property?propertyType=appartments",
        miniSubLinks: [
          {
            id: 1,
            linkText: "Rent",
            url: "/property?propertyType=appartments&purpose=rent",
          },
          {
            id: 2,
            linkText: "Sale",
            url: "/property?propertyType=appartments&purpose=sale",
          }
        ]
      },
      {
        id: 7,
        linkText: "Pent House",
        url: "/property?propertyType=penthouse",
        miniSubLinks: [
          {
            id: 1,
            linkText: "Rent",
            url: "/property?propertyType=penthouse&purpose=rent",
          },
          {
            id: 2,
            linkText: "Sale",
            url: "/property?propertyType=penthouse&purpose=sale",
          }
        ]
      }
    ]
  },
  {
    id: 5,
    linkText: "Services",
    url: "/services",
  },


  // {
  //   id: 5,
  //   linkText: "Buy",
  //   url: "/buy",

  // },
  // {
  //   id: 5,
  //   linkText: "Sell",
  //   url: "/sell",

  // },
  // {
  //   id: 5,
  //   linkText: "Rent",
  //   url: "/property?purpose=rent",
  // subLinks: [
  //   {
  //     id: 1,
  //     linkText: "To Give on Rent",
  //     url: "a",
  //   },
  //   {
  //     id: 2,
  //     linkText: "To Take on Rent",
  //     url: "/property?purpose=rent",
  //   }
  // ]
  // },
  {
    id: 6,
    linkText: "Blog",
    url: "/blog"
  },
  {
    id: 7,
    linkText: "Contact",
    url: "/contact"
  },

];
