import { useState, useEffect } from 'react';

const Position = (triggerHeight) => {
  const [isPastTrigger, setIsPastTrigger] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsPastTrigger(window.scrollY > triggerHeight);
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Check on mount

    return () => window.removeEventListener('scroll', handleScroll);
  }, [triggerHeight]);

  return isPastTrigger;
};

export default Position;
