import BlogPostList from "../components/blog/blog-4/BlogPostList";
import {
  Pagination,

} from "../components/common/page-componets";
// import HeroCarousel from "../components/common/page-componets/HeroCurosal";
import { feeds } from "../data/dummyData";

const Blog = () => {
  return (
    <>
      {/* <HeroCarousel /> */}
      <div className="pt-48 px-[3%] md:px-[6%]">
        <h1 className="text-2xl">HOME / <span className="text-primary">BLOG</span></h1>


        <BlogPostList />
        <Pagination itemsPerPage={6} pageData={feeds} />
      </div>

    </>
  );
};

export default Blog;
