import { BiChevronDown } from "react-icons/bi";
import { useDispatch } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import { openDropdown } from "../../features/uiSlice";
import useScrollPosition from './Position'; // Adjust path as needed

const SingleLink = ({ id, linkText, url, subLinks }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const isHome = location.pathname === '/';
  const isScrolledPastHero = useScrollPosition(500); // Adjust 500px to the height of your hero section

  const handleDropDown = (e) => {
    const linkCords = e.target.getBoundingClientRect();
    const center = (linkCords.left + linkCords.right) / 2;
    dispatch(openDropdown({ link: linkText, center }));
  };

  // Determine link color
  const linkColorClass = isHome && !isScrolledPastHero ? "text-white" : "text-gray-900 dark:text-slate-400";

  return (
    <div className="relative">
      <NavLink
        to={url}
        end
        key={id}
        className={`relative w-full font-bold px-3 py-[0.3rem] lg:px-4 flex items-center gap-x-1 transition-colors ${linkColorClass}`}
        onMouseOver={handleDropDown}
      >
        {linkText}
        {subLinks && <BiChevronDown className="ml-1" />}
      </NavLink>
    </div>
  );
};

export default SingleLink;
