import { useState } from 'react';
import CountUp from 'react-countup';
import ScrollTrigger from 'react-scroll-trigger';
import Typewriter from 'typewriter-effect';

const Hero = () => {
  const [counterState, setCounterState] = useState(false);

  return (
    <div className="relative overflow-hidden text-slate-300">
      {/* Background Video */}
      {/* <video
        autoPlay
        muted
        loop
        className="absolute inset-0 md:hidden object-cover w-full h-6/12 -z-10"
        style={{ filter: 'brightness(30%)', pointerEvents: 'none' }}
      >
        <source src="/images/hero-mobile1.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video> */}

      <video
        autoPlay
        muted
        loop
        
        className="absolute inset-0  md:block object-cover w-full h-full -z-10"
        style={{ filter: 'brightness(30%)', pointerEvents: 'none' }}
      >
        <source src="/images/hero.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      {/* Content */}
      <div className="relative pt-16 px-[3%] md:px-[6%] z-10 flex flex-col-reverse md:flex-row items-center h-[80vh] gap-2  mt-32">
        <div className="flex-1 basis-[20rem] md:mt-5 mt-[50px]">
          <h1 className="text-4xl font-bold capitalize md:text-6xl md:w-5/12 w-full">
          Let's Find A Home That's
          <span className='text-primary'>
            <Typewriter
              options={{
                strings: [
                  "Perfect for You"
                ],
                autoStart: true,
                loop: true,
                cursor: "|",
                delay: 10,
                deleteSpeed: 50,
              }}
            />
            </span>
          </h1>
          <div className="pl-3 mt-5 border-l-4 border-primary">
            <p className='md:w-6/12 md:text-2xl text-sm'>
              We are recognized for exceeding client expectations and delivering great results through our services to our worldwide clients.
            </p>
          </div>
          <ScrollTrigger onEnter={() => setCounterState(true)} onExit={() => setCounterState(false)}>
            <div className="mt-6 text-center flex gap-x-8">
              <div>
                <h1 className="text-2xl font-bold">
                  {counterState && <CountUp end={180} duration={5} />} <span className="text-sm text-primary">+</span>
                </h1>
                <p className='md:text-2xl text-sm'>Listed Properties</p>
              </div>
              <div>
                <h1 className="text-2xl font-bold">
                  {counterState && <CountUp end={120} duration={5} />} <span className="text-sm text-primary">+</span>
                </h1>
                <p className='md:text-2xl text-sm'>Happy Customers</p>
              </div>
              <div>
                <h1 className="text-2xl font-bold">
                  {counterState && <CountUp end={100} duration={5} />} <span className="text-sm text-primary">+</span>
                </h1>
                <p className='md:text-2xl text-sm'>Awards</p>
              </div>
            </div>
          </ScrollTrigger>
        </div>
      </div>
    </div>
  );
};

export default Hero;
