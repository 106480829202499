import { useState, useEffect } from 'react';
import { FiArrowRight } from "react-icons/fi";
// import { Link } from "react-router-dom";
import 'wow.js/css/libs/animate.css'; 
import WOW from 'wow.js';

const SingleFeedCardGrid = ({
  id,
  title,
  image,

  description,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  useEffect(() => {

    const wow = new WOW({
      live: false,
      offset: 100,
      mobile: true
    });
    wow.init();

    
    return () => {
    };
  }, []);

  return (
    <>
      <div className="flex flex-col gap-3 sm:flex-row group bg-white dark:bg-transparent shadow rounded-lg overflow-hidden wow fadeInLeft" data-wow-duration="2s" data-wow-delay="1mms">
        <div className="relative flex-shrink-0">
          <img
            src={image}
            alt={title}
            className="object-cover w-full h-64 rounded-lg sm:w-48 overflow-hidden"
          />
        </div>
        <div className="">


          <h1 onClick={openModal} className="text-lg cursor-pointer font-semibold capitalize group-hover:text-primary transition-a">{title}</h1>

          <p className="mt-2">{description.slice(0, 200)}...</p>
          <button
            onClick={openModal}
            className="mt-4 flex align-center gap-x-2 hover:underline text-primary"
          >
            <span className="uppercase hover:underline">read more</span>{" "}
            <FiArrowRight />
          </button>
        </div>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div
          id="default-modal"
          tabIndex="-1"
          aria-hidden="true"
          className="fixed inset-0 z-50 flex w-full h-full  m-auto  items-center justify-center   bg-gray-800 bg-opacity-50"
        >
          <div className="relative  overflow-scroll items-center z-50 p-4 w-10/12 h-96 md:h-4/6 md:w-5/12 md:h-9/12 bg-white rounded-lg shadow-2xl dark:bg-gray-700">
            <div className="flex items-center justify-between p-4 border-b rounded-t dark:border-gray-600">
              <h3 className="md:text-xl text-sm font-semibold text-gray-900 dark:text-white">
                {title}
              </h3>
              <button
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                onClick={closeModal}
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
              </button>
            </div>
            <div className="p-4">
              {/* Content can go here */}
            </div>
            <div className="p-4 space-y-4">
              <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                {description}
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SingleFeedCardGrid;
