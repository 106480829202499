import {
  Categories,
  Counter,
  Services as ServicesList,

} from "../components/common/page-componets";
// import HeroCarousel from "../components/common/page-componets/HeroCurosal";


const Services = () => {
  return (
    <>
      {/* <HeroCarousel /> */}
      <div className="pt-48 px-[3%] md:px-[6%]">
        <h1 className="text-2xl">HOME / <span className="text-primary">SERVICES</span></h1>

        <ServicesList />
        {/* <Categories /> */}
        <Counter />

      </div>
    </>
  );
};

export default Services;
